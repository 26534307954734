import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import hero from "./assets/images/hero.png";
import ww from "./assets/images/ww.png";
import { Container } from "./components/GridSystem";
import { langDiv } from "./contexts/useLang";
import {
  AboutUs,
  ContactUs,
  Footer,
  Navbar,
  PartnersOfSuccess,
  Products,
  ProductsDetails,
  Services,
} from "./pages";
import PrivacyPolicy from "./PrivacyPolicy";
import Flashlight from "./Flashlight";
const App = () => {
  const [openProduct, setOpenProduct] = useState({});
  const [target, setTarget] = useState("");
  const aboutUs = useRef();
  const products = useRef();
  const services = useRef();
  const contactUs = useRef();
  useEffect(() => {
    if (target === "about_us") {
      aboutUs?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else if (target === "products") {
      products?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else if (target === "services") {
      services?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else if (target === "contact_us") {
      contactUs?.current.scrollIntoView({ behavior: "smooth" });
      setTarget("");
    } else {
    }
  }, [target]);
  useEffect(() => {
    document.documentElement.dir =
      (localStorage.getItem("language") === "ar" &&
        localStorage.getItem("language")) ||
      (langDiv === "ar" && !localStorage.getItem("language"))
        ? "rtl"
        : "ltr";
  }, []);
  const [phoneNumber, setPhoneNumber] = useState("");
  useEffect(() => {
    // استدعاء API للحصول على معلومات الموقع
    fetch("https://ipapi.co/json/")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const countryCode = data.country_code;
        if (countryCode === "OM") {
          setPhoneNumber("+96871714930"); // عُمان
        } else if (countryCode === "EG") {
          setPhoneNumber("0021000332762"); // مصر
        } else {
          setPhoneNumber("+971504681550"); // افتراضي (الإمارات)
        }
      })
      .catch((err) => {});
  }, []);

  return (
    <div className=" relative">
      <Flashlight />
      <div
        onClick={() => {
          window.scrollTo({ behavior: "smooth", top: 0 });
          setTarget("");
        }}
        className={`fixed md:bottom-10 bottom-4 right-2 md:right-4  px-2 py-3  rounded-lg z-20 cursor-pointer bg `}
      >
        <a
          href={phoneNumber ? "https://wa.me/" + phoneNumber : "/"}
          target={phoneNumber ? "_blank" : "_self"}
        >
          <img
            src={ww}
            alt=""
            className="!cursor-pointer relative -z-10 -rotate-90 w-20 md:w-24 animate-pulse"
          />
        </a>
      </div>
      <Navbar setTarget={setTarget} />
      <Routes>
        <Route
          path="/"
          element={
            <div className="overflow-x-hidden">
              <div className="md:h-[80vh] overflow-hidden hero-section rounded-b-[50px] mb-10 relative">
                <img src={hero} alt="" />
              </div>
              <Container>
                <div ref={aboutUs}>
                  <AboutUs />
                </div>
                <ProductsDetails openProduct={openProduct} />
                <div ref={products}>
                  <Products
                    setOpenProduct={setOpenProduct}
                    openProduct={openProduct}
                  />
                </div>
                <div ref={services}>
                  <Services setTarget={setTarget} />
                </div>
                <div>
                  <PartnersOfSuccess />
                </div>
                <div ref={contactUs}>
                  <ContactUs phoneNumber={phoneNumber} />
                </div>
              </Container>
            </div>
          }
        />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
      </Routes>

      <Footer />
    </div>
  );
};

export default App;
