import { useEffect } from "react";

const Flashlight = () => {
  useEffect(() => {
    const lightCircle = document.createElement("div");
    lightCircle.classList.add("light-circle");
    document.body.appendChild(lightCircle);

    const heroSection = document.querySelector(".hero-section");
    const heroBackground = document.querySelector(".hero-background");

    // Position the light at 75% width & bottom
    const updateLightPosition = () => {
      const circleSize = 100;
      const leftPosition = window.innerWidth * 0.75 - circleSize / 2;
      const bottomPosition = window.innerHeight - circleSize;

      lightCircle.style.left = `${leftPosition}px`;
      lightCircle.style.top = `${bottomPosition}px`;

      if (heroBackground) {
        heroBackground.style.maskImage = `radial-gradient(circle at ${
          leftPosition + 50
        }px ${
          bottomPosition + 50
        }px, rgba(0, 0, 0, 1) 100px, rgba(0, 0, 0, 0) 1300px)`;
        heroBackground.style.webkitMaskImage = `radial-gradient(circle at ${
          leftPosition + 50
        }px ${
          bottomPosition + 50
        }px, rgba(0, 0, 0, 1) 100px, rgba(0, 0, 0, 0) 1300px)`;
      }
    };

    // Scroll effect
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const fadePoint = 500;
      const opacity = Math.max(1 - scrollY / fadePoint, 0);
      if (heroSection) heroSection.style.opacity = opacity;
    };

    updateLightPosition(); // Set initial position
    window.addEventListener("resize", updateLightPosition);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateLightPosition);
      lightCircle.remove();
    };
  }, []);

  return null;
};

export default Flashlight;
